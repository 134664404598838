import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppointmentFormOpeningEvent } from "devextreme/ui/scheduler"

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent {
  appointmentsData: any;
  resourceData: any;
  mediciData: any;

  currentDate: Date = new Date();
  backendURL: string = environment.ApiUrl + '/api';

  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private toolbar: ToolbarService,
    private router: Router
    ) {
    const currentUser = localStorage.getItem("userId");
    
    this.appointmentsData = createStore({
      key: 'areaManagerAppointmentId',
      loadUrl: `${this.backendURL}/AreaManagerAppointments/GetbyUserId?userName=` + currentUser,
      insertUrl: `${this.backendURL}/AreaManagerAppointments/Post`,
      updateUrl: `${this.backendURL}/AreaManagerAppointments/Put`,
      deleteUrl: `${this.backendURL}/AreaManagerAppointments/Delete`,
      //onBeforeSend(method,ajaxOptions) {
      //  ajaxOptions.xhrFields = { withCredentials: true };
      //},
    });

    this.resourceData = createStore({
      key: 'appointmentActivityId',
      loadUrl: `${this.backendURL}/AppointmentActivities/GetForAppointment`,
    });



    this.mediciData = createStore({
      key: "userId",
      loadUrl: `${this.backendURL}/Utenti/GetAgentiAreaManager?userName=` + currentUser,
    });
  }

  onAppointmentFormOpening(data: any) {
    const that = this;
    const form = data.form;
    let startDate = data.appointmentData.startDate;
    
    
    form.option('items', [{
      label: {
        text: 'Inizio',
      },
      dataField: 'startDate',
      isRequired: true,
      editorType: 'dxDateBox',
      editorOptions: {
        width: '100%',
        type: 'datetime',
      },
      }, {
      label: {
        text: 'Fine',
      },
      name: 'endDate',
      dataField: 'endDate',
      validationRules: [{
        type: 'required',
        message: 'Data di fine � obbligatoria',
      }, {
        type: 'compare',
        message: "Fine precedente ad inizio",
        comparisonType: '>',
        comparisonTarget() {
          return form.option('formData').startDate;
        },
      }],
      editorType: 'dxDateBox',
      editorOptions: {
        width: '100%',
        type: 'datetime',
      },
      },
      {
        label: {
          text: 'Attivit� Svolta',
        },
        editorType: 'dxSelectBox',
        dataField: 'appointmentActivityId',
        isRequired: true,
        editorOptions: {          
          displayExpr: 'descrizione',
          valueExpr: 'appointmentActivityId',
          dataSource: {
            store: createStore({
              key: 'appointmentActivityId',
              loadUrl: this.backendURL + '/AppointmentActivities/Get',
            }),
            sort: ['descrizione']
          }
          
        },
      }, {
        label: {
          text: 'Note',
        },
        name: 'note',
        dataField: 'note',
        editorType: 'dxTextArea',        
      },
      {
        label: {
          text: 'Informatori',
        },
        name: 'informatori',
        dataField: 'informatori',
        isRequired: true,
        editorType: 'dxTagBox',
        editorOptions: {
          displayExpr: 'nome',
          valueExpr: 'userId',
          dataSource: createStore({
            key: "userId",
            loadUrl: this.backendURL + `/Utenti/GetAgentiAreaManager?userName=` + localStorage.getItem("userId"),
          }),
          showSelectionControls: true
        },
      }, 
    ]);

    // Only for new appointments
    if (data.appointmentData.areaManagerAppointmentId == null) {

      var startDateEditor = form.getEditor('startDate');
      var endDateEditor = form.getEditor('endDate');

      var startDateEditorValue = new Date(startDateEditor.option('value'));
      var endDataEditorValue = new Date(endDateEditor.option('value'));

      // Calcolo il delta in formato di ore totali
      var delta = (endDataEditorValue.getTime() - startDateEditorValue.getTime() ) / 1000 / 60 / 60;

      if (delta > 15) {
        var startDateValue = new Date(startDateEditor.option('value'));
        startDateEditor.option('value', new Date(startDateValue.getFullYear(), startDateValue.getMonth(), startDateValue.getDate(), 8, 0, 0));

        endDateEditor.option('value', new Date(startDateValue.getFullYear(), startDateValue.getMonth(), startDateValue.getDate(), 9, 0, 0));
      }
    }

    form.updateData('userName', localStorage.getItem("userId"));

  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Calendario");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(false);
    this.toolbar.ChartVisibleChanged(true);
    this.toolbar.chartClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe((_) => this.Chart());

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  Chart(): void {
    this.router.navigate(['/appointment-dashboard']);
  }
}
