import { Component, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { confirm, alert } from 'devextreme/ui/dialog';
import { InvestmentRequest, InvestmentRequestKind } from '../../shared/models/investment-request';
import { ToolbarService } from '../../shared/services/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../shared/services/logging.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../environments/environment';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxNumberBoxTypes } from 'devextreme-angular/ui/number-box';
import { RestApiService } from '../../shared/services/rest-api.service';
import { InvestmentRequestsDbService } from '../../shared/services/investment-requests-db.service';
import { DxDateBoxTypes } from 'devextreme-angular/ui/date-box';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DxLookupTypes } from 'devextreme-angular/ui/lookup';

@Component({
  selector: 'app-investment-request',
  templateUrl: './investment-request.component.html',
  styleUrl: './investment-request.component.scss'
})
export class InvestmentRequestComponent {
  @ViewChild(DxFormComponent, {static:true}) form!: DxFormComponent;

  investmentRequestTypesDataSource: DataSource;
  informatoriDataSource: DataSource;
  investmentBudgetTypesDataSource: DataSource;
  investmentRequestStatesDataSource: DataSource;
  investmentRequestStatesFullDataSource: DataSource;
  districtDataSource: DataSource;
  investmentRequestVoucherTypesDataSource: DataSource;

  investmentRequest: InvestmentRequest;
  currentInvestmentRequestKind: InvestmentRequestKind | null = null;
  allInvestmentRequestKinds = InvestmentRequestKind;

  currenttravelCheckIn: Date | null = null;
  currenttravelCheckOut: Date | null = null;

  investmentRequestId: number | null;
  backendURL: string = environment.ApiUrl + '/api';
  isAreaManager = (localStorage.getItem("isAreaManager") == "S");
  isAdministrator = (localStorage.getItem("isAdministrator") == "S");
  maxDate: string;
  minDate: string;
  minImporto: number = 0.01;
  maxImporto: number = 500000;
  isFormReadOnly: boolean = false;

  informatoreEditorOptions: DxLookupTypes.Properties;
  investmentRequestTypeEditorOptions: DxLookupTypes.Properties;
  investmentBudgetTypeEditorOptions: DxLookupTypes.Properties;
  investmentRequestStatesEditorOptions: DxLookupTypes.Properties;
  conventionRegistrationDistrictEditorOptions: DxLookupTypes.Properties;
  investmentRequestVoucherTypeEditorOptions: DxLookupTypes.Properties;

  notesEditorOptions = { height: 90 };
  restaurantMailEditorOption = { mode: "email" };
  restaurantPhoneEditorOption = { mode: "tel" };
  positiveIntegerEditorOptions: DxNumberBoxTypes.Properties = {
    min: 0,
    format: {
      type: "fixedpoint", // one of the predefined formats
      precision: 0, // the precision of values
    }
  }

  importoEditorOptions: DxNumberBoxTypes.Properties = {
    min: 0,
    format: {
      type: "currency", // one of the predefined formats
      precision: 2, // the precision of values
      currency: "EUR"
    }
  }

  dataEditorOptions: DxDateBoxTypes.Properties = {
    dateSerializationFormat: "yyyy-MM-dd"
  }

  private unsubscriber: Subject<void> = new Subject<void>();

  travelTreatmentEditorOptions : DxSelectBoxTypes.Properties = {
    placeholder: "Selezionare il trattamento",
    items: [
      'BB - Bed & Breakfast',
      'HB - Mezza Pensione',
    ],
    showClearButton: true
  };

  travelRoomEditorOptions: DxSelectBoxTypes.Properties = {
    placeholder: "Selezionare il tipo di camera",
    items: [
    'DUS - Doppia uso singola',
    'DBL - Doppia',
    'TPL - Tripla',
    ],
    showClearButton: true
  };

  aggiungiMedicoButtonOptions: DxButtonTypes.Properties = {
    text: 'Aggiungi Medico',
    type: 'default',
    width: '180px',
    onClick: () => {
      const result = this.form.instance.validate();

      if (result.isValid) {
        this.investmentRequestDb.addInvestmentRequest(this.investmentRequest).then(redId => this.router.navigate(['/investment-request-doctor', { id: redId }]));
      }
    },
  };

  submitButtonOptions: DxButtonTypes.Properties = {
    text: 'Invia Richesta',
    type: 'default',
    width: '180px',
    onClick: () => {
      const result = this.form.instance.validate();

      let medici = this.investmentRequest.investmentRequestDoctors?.length ?? 0;

      // Nelle sponsorizzazioni non serve inserire un medico
      if (this.currentInvestmentRequestKind === InvestmentRequestKind.SponsorizzazioneCongressi) medici = 1;

      if (result.isValid && medici > 0) {
        this.restApi.putInvestmentRequest(this.investmentRequest).pipe(takeUntil(this.unsubscriber)).subscribe(() => this.router.navigate(['/investment-requests']));
      }
      else if (medici == 0) {
        alert("Inserire almeno un medico", "Medici mancanti");
      }
    },    
  };

  companyVisitCheckInComparison = () => this.investmentRequest?.companyVisitCheckIn;
  travelCheckInComparison = () => this.investmentRequest?.travelCheckIn;

  constructor(
    private toolbar: ToolbarService,
    private restApi: RestApiService,
    private route: ActivatedRoute,
    private investmentRequestDb: InvestmentRequestsDbService,
    private logger: LoggingService,
    private router: Router) {

    this.investmentRequestId = null;
    this.investmentRequest = new InvestmentRequest(0);

    this.investmentRequestTypesDataSource = new DataSource({
      store: createStore({
        key: 'investmentRequestTypeId',
        loadUrl: this.backendURL + '/InvestmentRequestTypes/Get'
      }),
      sort: 'descrizione'
    });

    this.investmentRequestTypeEditorOptions = {
      dataSource: this.investmentRequestTypesDataSource,      
      searchEnabled: true,
      displayExpr: "descrizione",
      valueExpr: "investmentRequestTypeId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il tipo",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    const currentUser = localStorage.getItem("userId");

    this.investmentRequestStatesDataSource = new DataSource({
      store: createStore({
      key: 'investmentRequestStateId',
        loadUrl: this.backendURL + '/InvestmentRequestStates/Get?userName=' + currentUser,
      }),
      sort: 'descrizione'
    });

    this.investmentRequestStatesFullDataSource = new DataSource({
      store: createStore({
        key: 'investmentRequestStateId',
        loadUrl: this.backendURL + '/InvestmentRequestStates/Get',
      }),
      sort: 'descrizione'
    });

    this.investmentRequestStatesEditorOptions = {
      dataSource: this.investmentRequestStatesDataSource,
      searchEnabled: true,
      displayExpr: "descrizione",
      valueExpr: "investmentRequestStateId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare lo stato",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.investmentBudgetTypesDataSource = new DataSource({
      store: createStore({
      key: 'investmentBudgetTypeId',
        loadUrl: this.backendURL + '/InvestmentBudgetTypes/GetForInvestmentRequest',
      }),
      sort: 'descrizione'
    });

    this.investmentBudgetTypeEditorOptions = {
      dataSource: this.investmentBudgetTypesDataSource,
      searchEnabled: true,
      displayExpr: "descrizione",
      valueExpr: "investmentBudgetTypeId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il tipo Budget",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };
    
    this.informatoriDataSource = new DataSource({
      store: createStore({
      key: 'userId',
      loadUrl: this.backendURL + '/Utenti/GetAgenti?userName=' + currentUser,
      }),
      sort: 'nome'
    });

    this.informatoreEditorOptions = {
      dataSource: this.informatoriDataSource,
      searchEnabled: true,
      displayExpr: "nome",
      valueExpr: "userId",
      showClearButton: true,
      searchExpr: "nome",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare l'informatore",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.districtDataSource = new DataSource({
      store: createStore({
        key: 'districtId',
        loadUrl: this.backendURL + '/Districts/Get'
      }),
      sort: 'descrizione'
    });

    this.conventionRegistrationDistrictEditorOptions = {
      dataSource: this.districtDataSource,
      searchEnabled: true,
      displayExpr: "descrizione",
      valueExpr: "districtId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il comune",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.investmentRequestVoucherTypesDataSource = new DataSource({
      store: createStore({
        key: 'investmentRequestVoucherTypeId',
        loadUrl: this.backendURL + '/InvestmentRequestVoucherTypes/Get'
      }),
      sort: 'descrizione'
    });

    this.investmentRequestVoucherTypeEditorOptions = {
      dataSource: this.investmentRequestVoucherTypesDataSource,
      searchEnabled: true,
      displayExpr: "descrizione",
      valueExpr: "investmentRequestVoucherTypeId",
      showClearButton: true,
      searchExpr: "descrizione",
      searchMode: "contains",
      usePopover: false,
      wrapItemText: true,
      placeholder: "Selezionare il tipo di buono",
      dropDownOptions: {
        fullScreen: true,
        showTitle: false
      }
    };

    this.onEditButtonClick = this.onEditButtonClick.bind(this);


    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth() - 2, 1).toISOString().substring(0,10);
    this.maxDate = new Date(today.getFullYear() + 1, today.getMonth(), 1).toISOString().substring(0, 10);

  }

  onFieldDataChanged(e: FieldDataChangedEvent) {
    if (e.dataField === 'investmentRequestTypeId') {
      this.currentInvestmentRequestKind = e.value;
    }
    if (e.dataField === 'travelCheckIn') {
      this.currenttravelCheckIn = e.value;
    }
    if (e.dataField === 'travelCheckOut') {
      this.currenttravelCheckOut = e.value;
    }
  }

  onEditButtonClick(e: DxDataGridTypes.ColumnButtonClickEvent){
    const result = this.form.instance.validate();

    if (e.row && result.isValid) {
      if (this.isFormReadOnly) {
        this.router.navigate(['/investment-request-doctor', { requestId: this.investmentRequestId, doctorId: e.row?.key }]);
      }
      else {
        this.investmentRequestDb.addInvestmentRequest(this.investmentRequest).then(redId => this.router.navigate(['/investment-request-doctor', { id: redId, doctorId: e.row?.key }]))
      }
    }
  }

  ngOnInit(): void {
    this.toolbar.TitleChanged("Richiesta Investimento");
    this.toolbar.CollapseVisibleChanged(false);
    this.toolbar.RefreshVisibleChanged(false);
    this.toolbar.BackVisibleChanged(true);
    this.toolbar.ChartVisibleChanged(false);

    this.toolbar.backClickedEvent.pipe(takeUntil(this.unsubscriber)).subscribe(() => this.Back());

    const regId = this.route.snapshot.paramMap.get('requestId');
    if (regId) {
      this.investmentRequestId = Number(regId);
      this.restApi.getInvestmentRequest(this.investmentRequestId).pipe(takeUntil(this.unsubscriber)).subscribe(result => {
        this.investmentRequest = result;

        this.submitButtonOptions.text = 'Modifica Richiesta';

        this.isFormReadOnly = this.investmentRequest.investmentRequestStateId > 1;

        if (this.isAreaManager) {
          this.isFormReadOnly = (this.investmentRequest.investmentRequestStateId > 2);
        }
         
        if (this.isAdministrator) this.isFormReadOnly = false;

        if (this.isFormReadOnly) {
          this.investmentRequestStatesEditorOptions.dataSource = this.investmentRequestStatesFullDataSource;
        }
      });
    }

    const internalId = this.route.snapshot.paramMap.get('id');
    if (internalId) {
      this.investmentRequestId = Number(internalId);
      this.investmentRequestDb.getInvestmentRequest(this.investmentRequestId).then(i => { if (i) this.investmentRequest = i; });
    }

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe(() => {
      history.pushState(null, '');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  Back(): void {
    if (this.isFormReadOnly) {
      this.router.navigate(['/investment-requests']);
    }
    else {
      const result = confirm("<i>Sei sicuro?</i><br>I dati inseriti andranno persi", "Conferma Abbandono");
      result.then((dialogResult) => {
        if (dialogResult) {
          // Torno all'indice
          this.router.navigate(['/investment-requests']);
        }
      });
    }
    
  }

  validateBuonoCarburante(e: { value: number; }) {
    return e.value % 50 === 0;
  }
}
